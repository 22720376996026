<template>
  <c-form-control
    mb="30px"
    :is-required="isRequired"
    :is-invalid="$v.value.$invalid"
  >
    <c-form-label
      fontSize="14px"
      color="#555555"
      fontWeigh="400"
      fontFamily="Roboto"
    >
      {{ question }}
    </c-form-label>
    <c-radio-group
      v-model="value__"
      :required="isRequired"
      :is-required="isRequired"
    >
      <c-radio
        v-for="item in selections"
        :key="item.id"
        size="lg"
        :value="item.answer"
        :disabled="isDisabled"
        :is-disabled="isDisabled"
      >
        {{ item.answer }}
      </c-radio>
    </c-radio-group>
    <c-form-helper-text font-size="12px">
      {{ subtext }}
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators"

export default {
  name: "radio-answer",
  props: [
    "question",
    "isRequired",
    "selections",
    "value",
    "subtext",
    "isDisabled",
  ],
  computed: {
    value__: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      },
    },
  },
  validations: {
    value: {
      required: requiredIf(function () {
        return this.isRequired
      }),
    },
  },
}
</script>

<style scoped></style>
