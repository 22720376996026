<template>
  <c-form-control
    mb="30px"
    :is-required="isRequired"
    :is-invalid="$v.value.$invalid"
  >
    <c-form-label
      fontSize="14px"
      color="#555555"
      fontWeigh="400"
      fontFamily="Roboto"
    >
      {{ question }}
    </c-form-label>
    <textarea
      :class="{invalid: $v.value.$invalid}"
      rows="3"
      v-chakra
      id="story"
      :placeholder="subtext"
      d="block"
      border="1px solid #E2E8F0"
      border-radius="md"
      minHeight="150px"
      type="text"
      lineHeight="20px"
      p="10px"
      w="100%"
      :required="isRequired"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :disabled="isDisabled"
    />
    <c-form-helper-text font-size="12px">
      {{ subtext }}
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";

export default {
  name: "paragraph-answer",
  props: ["question", "isRequired", "value", "subtext", "isDisabled"],
  validations: {
    value: {
      required: requiredIf(function () {
        return this.isRequired;
      }),
    },
  },
};
</script>

<style scoped>
textarea.invalid {
  border: 1px solid #e66673;
  box-shadow: 0 0 0 1px #e66673;
}
</style>
